import React from 'react'

import Layout from '../components/playout'
import Catering from '../components/index/press'
import Intro from '../components/index/pressintro'
import Slider1 from '../components/index/slide1'

export default ({ data }) => {
  return (
    <Layout>
      <Intro />
      <Catering />
    </Layout>
  )
}
