import React from 'react'

export default props => (
  <footer>
    <div className='footer-main'>

      <div className='row'>
        <div className='col-four tab-full mob-full footer-info'>
          <div className='footer-logo' />
          <p>
            ROBOTIC KITCHEN INDIA <br />
            Kanniyappan Street,<br /> Ramapuram, <br />Chennai, India, 600 089
          </p>
        </div>

        <div className='col-two tab-1-3 mob-1-2 site-links'>
          <h4>Site Links</h4>
          <ul>
            <li><a href='#process'>About Us</a></li>
            <li><a href='/blog'>Blog</a></li>
            <li><a href='#faq'>FAQ</a></li>
            <li><a href='#contact'>Contact</a></li>
          </ul>
        </div>

        <div className='col-two tab-1-3 mob-1-2 social-links'>
          <h4>Social</h4>
          <ul>
            <li><a target="_blank" rel="noopener noreferrer" href='https://twitter.com/RoboticKitchen'>Twitter</a></li>
            <li><a target="_blank" rel="noopener noreferrer" href='https://www.instagram.com/robotickitchen/'>Instagram</a></li>
            <li><a target="_blank" rel="noopener noreferrer" href='https://www.youtube.com/channel/UCjuIHhvnVUxa97B7gLFIHTQ'>Youtube</a></li>
          </ul>
        </div>

        <div className='col-four tab-1-3 mob-full footer-subscribe'>
          <h4>Subscribe</h4>
          <p>Keep yourself updated. Subscribe to our newsletter.</p>
          <div className='subscribe-form'>
            <form id='mc-form' className='group' noValidate>
              <input type='email' defaultValue='' name='dEmail' className='email' id='mc-email' placeholder='type email &amp; hit enter' required='' />
              <input type='submit' name='subscribe' />
              <label htmlFor='mc-email' className='subscribe-message' />
            </form>
          </div>
        </div>
      </div>
    </div>

    <div className='footer-bottom'>
      <div className='row'>
        <div className='col-twelve'>
          <div className='copyright'>
            <span>© Copyright RoboChef 2020.</span><br></br>
            <span>Admatic Solutions</span>
            {/* <span>Design by <a href='http://www.styleshout.com/' rel="noopener">styleshout</a></span> */}
          </div>

          <div id='go-top' style={{ display: 'block' }}>
            {/* <a className='smoothscroll' title='Back to Top' href='#top'><i className='icon ion-android-arrow-up' /></a> */}
          </div>
        </div>
      </div>
    </div>
  </footer>
)
