import React from 'react'

class Catering extends React.Component {
  render () {
    return (
      <section id='pricing1'>

        <div className='row section-intro'>
          <div className='col-twelve with-bottom-line'>

            {/* <h5>Our Pricing</h5> */}
            <br></br><br></br>
            {/* <h1>RoboChef Gallery</h1> */}

            <p className='lead'> RoboChef and It's Recognition.</p>

          </div>
        </div>

        <div class="row pricing-content">

         <div class="pricing-tables block-1-2 group">


         <div class="bgrid">
                  <a target="_blank" rel="noopener noreferrer" href="https://www.pressreader.com/india/the-hindu/20191113/282419876075536">
                  <div class="price-block-gallery">

                      <img loading="lazy" src="/images/press/5.jpg" alt="Automated Robotic Kitchen" />
                      <h3 class="plan-title">Cooking 2.O </h3><br></br>by<br></br> The Hindu 
              

                </div>
                </a>
        </div>

        <div class="bgrid">
                  <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/embed/r57EYBOdJW0">
                  <div class="price-block-gallery">

                      <img loading="lazy" src="/images/press/robochef_poli.jpg" width="100%" alt="Automated Robotic Kitchen" />
                      <h3 class="plan-title">Tv & Media </h3><br></br>by<br></br> Polimer News
              

                </div>
                </a>
        </div>


        <div class="bgrid">
                  <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/embed/xk0uUKRYZd4">
                  <div class="price-block-gallery">

                      <img loading="lazy" src="/images/press/robochef_vendhar.jpg" width="100%" alt="Automated Robotic Kitchen" />
                      <h3 class="plan-title">Tv & Media </h3><br></br>by<br></br> Vendhar Tv
              

                </div>
                </a>
        </div>


        <div class="bgrid">
                  <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/embed/FdFwdWkmW4k">
                  <div class="price-block-gallery">

                      <img loading="lazy" src="/images/press/robochef_thanthi.jpg" width="100%" alt="Automated Robotic Kitchen" />
                      <h3 class="plan-title">Tv & Media </h3><br></br>by<br></br> Thanthi Tv
              

                </div>
                </a>
        </div>



            <div class="bgrid">
              <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/TamilTheHindu/videos/533559187377320/">
                  <div class="price-block-gallery">

                      <img loading="lazy" src="/images/press/1.jpg" alt="Automated Robotic Kitchen" />
                      <h3 class="plan-title">'Automated Robotic Kitchen' </h3><br></br>by<br></br> Tamil The Hindu 
              

                  </div>
                  </a>
			      </div>


            
            <div class="bgrid">
            <a target="_blank" rel="noopener noreferrer" href="https://www.manoramanews.com/news/india/2019/11/04/robotic-chef-in-chennai.html">
            <div class="price-block-gallery">

                <img loading="lazy" src="/images/press/6.jpg" alt="Automated Robotic Kitchen" />
                <h3 class="plan-title">ആപ്പൊന്ന് തുറന്നാൽ അടുക്കളയിൽ ചൂടോടെ ഭക്ഷണം തയ്യാർ; എത്തി യന്തിരൻ ഷെഫ്... </h3><br></br>by<br></br> manoramanews 
        

            </div>
            </a>
            </div>

            

			      <div class="bgrid">
            <a target="_blank" rel="noopener noreferrer" href="https://newstodaynet.com/index.php/2019/11/02/robo-kitchen-serves-3000-customers-on-daily-basis/">
               <div class="price-block-gallery">

                  <img loading="lazy" src="/images/press/2.jpg" alt="Robo Kitchen Serves 3000 Customers daily" height="500" width="500"/>
	            		<h3 class="plan-title">Robo Kitchen Serves 3000 Customers daily</h3> <br></br>by<br></br> Newstodaynet 

            	</div>
            </a>
			   </div> 

            <div class="bgrid">
                  <a target="_blank" rel="noopener noreferrer" href="https://tamil.news18.com/news/lifestyle/food-a-startup-restaurant-in-chennai-attempts-to-cooks-with-robotic-machines-esr-226207.html">
                  <div class="price-block-gallery">

                      <img loading="lazy" src="/images/press/3.jpg" alt="Automated Robotic Kitchen" />
                      <h3 class="plan-title">பிரியாணி முதல் கேசரி வரை உணவு சமைக்க ரோபோ கருவி... சென்னை ரெஸ்டாரண்டின் புது முயற்சி...! </h3><br></br>by<br></br> News18 
              

                  </div>
                  </a>
			      </div>


            <div class="bgrid">
                    <a target="_blank" rel="noopener noreferrer" href="https://newstodaynet.com/wp-content/uploads/2019/11/2-1.jpg#main">
                    <div class="price-block-gallery">
                        <img loading="lazy" src="/images/press/27.jpg" alt="Automated Robotic Kitchen" />
                        <h3 class="plan-title">Robo Kitchen serves 3000 customers on a daily basis </h3><br></br>by<br></br> Newstodaynet
                    </div>
                    </a>
            </div>

            <div class="bgrid">
                  <a target="_blank" rel="noopener noreferrer" href="http://www.thaarakam.com/news/96705">
                  <div class="price-block-gallery">

                      <img loading="lazy" src="/images/press/4.jpg" alt="Automated Robotic Kitchen" />
                      <h3 class="plan-title">600 வரையான உணவுகளை அசத்தலாக சமைக்கும் ரோபோ சமையல்காரன் </h3><br></br>by<br></br> Thaarakam 
              

                  </div>
                  </a>
			      </div>


            <div class="bgrid">
                  <a target="_blank" rel="noopener noreferrer" href="https://epaper.andhrajyothy.com/m5/2410836/Tamilnadu/10-11-2019#page/7/1">
                  <div class="price-block-gallery">
                      <img loading="lazy" src="/images/press/7.jpg" alt="Automated Robotic Kitchen" />
                      <h3 class="plan-title">Robotic Kitchen </h3><br></br>by<br></br> AndhraJyothy 
                  </div>
                  </a>
			      </div>

            <div class="bgrid">
                  <a target="_blank" rel="noopener noreferrer" href="http://cinebazaar.news/?p=7523">
                  <div class="price-block-gallery">
                      <img loading="lazy" src="/images/press/8.jpg" alt="Automated Robotic Kitchen" />
                      <h3 class="plan-title">பெண்களுக்கு விடுதலையளிக்க வேண்டும் என்று உணவுத்துறை விஞ்ஞானிகள் கண்டுபிடிப்பு </h3><br></br>by<br></br> CineBazaar 
                  </div>
                  </a>
			      </div>

            <div class="bgrid">
                  <a target="_blank" rel="noopener noreferrer" href="https://epaper.mathrubhumi.com/Home/ShareArticle?OrgId=70d7e7e4&imageview=0">
                  <div class="price-block-gallery">
                      <img loading="lazy" src="/images/press/9.jpg" alt="Automated Robotic Kitchen" />
                      <h3 class="plan-title">ആപ്പ് തുറന്നാൽ ഭക്ഷണം തയ്യാർ: ചെന്നൈയിൽ റോബോ കിച്ചൺ </h3><br></br>by<br></br> mathrubhumi 
                  </div>
                  </a>
			      </div>

            <div class="bgrid">
                  <a target="_blank" rel="noopener noreferrer" href="http://www.tamilfrance.fr/%E0%AE%9A%E0%AE%BF%E0%AE%A9%E0%AE%BF%E0%AE%AE%E0%AE%BE/%E0%AE%AE%E0%AF%81%E0%AE%B4%E0%AF%81%E0%AE%B5%E0%AE%A4%E0%AF%81%E0%AE%AE%E0%AF%8D-%E0%AE%A4%E0%AE%BE%E0%AE%A9%E0%AE%BF%E0%AE%AF%E0%AE%99%E0%AF%8D%E0%AE%95%E0%AE%BF-%E0%AE%AE%E0%AF%81%E0%AE%B1%E0%AF%88/">
                  <div class="price-block-gallery">
                      <img loading="lazy" src="/images/press/10.jpg" alt="Automated Robotic Kitchen" />
                      <h3 class="plan-title">Fully Automated Robotic Kitchen </h3><br></br>by<br></br> TamilFrance
                  </div>
                  </a>
			      </div>

            <div class="bgrid">
                  <a target="_blank" rel="noopener noreferrer" href="http://cineinfotv.com/2019/11/robo-chef-the-worlds-first-only-fully-automated-robotic-kitchen/">
                  <div class="price-block-gallery">
                      <img loading="lazy" src="/images/press/11.jpg" alt="Automated Robotic Kitchen" />
                      <h3 class="plan-title">Robo Chef – The World’s First & Only Fully Automated Robotic Kitchen </h3><br></br>by<br></br> CineInfoTV
                  </div>
                  </a>
			      </div>

            <div class="bgrid">
                  <a target="_blank" rel="noopener noreferrer" href="http://royalreporter.in/?p=4943">
                  <div class="price-block-gallery">
                      <img loading="lazy" src="/images/press/12.jpg" alt="Automated Robotic Kitchen" />
                      <h3 class="plan-title">அறிமுகமானது – ‘ரோபோசெஃப்’ = இந்தியாவில் முதல் சமைக்கும் எந்திர மனிதன்.! </h3><br></br>by<br></br> RoyalReporter
                  </div>
                  </a>
			      </div>

            <div class="bgrid">
                  <a target="_blank" rel="noopener noreferrer" href="https://www.pathivu.com/2019/11/first-cook-robot-chef.html">
                  <div class="price-block-gallery">
                      <img loading="lazy" src="/images/press/13.jpg" alt="Automated Robotic Kitchen" />
                      <h3 class="plan-title">600 வகையான உணவு சமைக்கும் "ரோபோ செஃப்", கண்டுபிடித்த தமிழன்! </h3><br></br>by<br></br> Pathivu
                  </div>
                  </a>
			      </div>

            <div class="bgrid">
                  <a target="_blank" rel="noopener noreferrer" href="https://tamil.thesubeditor.com/news/india/16982-indias-first-machine-cook-robot-chef-has-arrived.html">
                  <div class="price-block-gallery">
                      <img loading="lazy" src="/images/press/14.jpg" alt="Automated Robotic Kitchen" />
                      <h3 class="plan-title">இந்தியாவின் முதல் இயந்திர சமையல்காரர்.. வந்து விட்டது ரோபோ செஃப்.. </h3><br></br>by<br></br> thesubeditor
                  </div>
                  </a>
			      </div>

            <div class="bgrid">
                  <a target="_blank" rel="noopener noreferrer" href="http://faceinews.com/?p=17129">
                  <div class="price-block-gallery">
                      <img loading="lazy" src="/images/press/15.jpg" alt="Automated Robotic Kitchen" />
                      <h3 class="plan-title">முழுவதும் தானியங்கி முறையில் சமையல் செய்யும் ‘ரோபோசெஃப்’ (ROBOCHEF) </h3><br></br>by<br></br> faceinews
                  </div>
                  </a>
			      </div>

            <div class="bgrid">
                  <a target="_blank" rel="noopener noreferrer" href="http://chennaipatrika.com/post/RoboChef-The-Worlds-First-Only-Fully-Automated-Robotic-Kitchen">
                  <div class="price-block-gallery">
                      <img loading="lazy" src="/images/press/16.jpg" alt="Automated Robotic Kitchen" />
                      <h3 class="plan-title">RoboChef - The Worlds First & Only Fully Automated Robotic Kitchen </h3><br></br>by<br></br> chennaipatrika
                  </div>
                  </a>
			      </div>

            <div class="bgrid">
                  <a target="_blank" rel="noopener noreferrer" href="http://chennaipressnews.blogspot.com/2019/11/robo-chef-worlds-first-only-fully.html">
                  <div class="price-block-gallery">
                      <img loading="lazy" src="/images/press/17.jpg" alt="Automated Robotic Kitchen" />
                      <h3 class="plan-title">Robo Chef - The World’s First & Only Fully Automated Robotic Kitchen </h3><br></br>by<br></br> chennaipressnews
                  </div>
                  </a>
			      </div>

            <div class="bgrid">
                  <a target="_blank" rel="noopener noreferrer" href="http://www.tamilcineboxoffice.com/robochef/">
                  <div class="price-block-gallery">
                      <img loading="lazy" src="/images/press/18.jpg" alt="Automated Robotic Kitchen" />
                      <h3 class="plan-title">தானியங்கி முறையில் முழுவதும் சமையல் செய்யும் ‘ரோபோசெஃப்’(ROBOCHEF) </h3><br></br>by<br></br> tamilcineboxoffice
                  </div>
                  </a>
			      </div>

            <div class="bgrid">
                  <a target="_blank" rel="noopener noreferrer" href="https://makkalnanayam.com/news/?p=3305">
                  <div class="price-block-gallery">
                      <img loading="lazy" src="/images/press/19.jpg" alt="Automated Robotic Kitchen" />
                      <h3 class="plan-title">உலகிலேயே புதிய தொழில்நுட்பத்துடன் ரோபோசெஃப் கிச்சன் (Robo Chef kitchen )   </h3><br></br>by<br></br> makkalnanayam
                  </div>
                  </a>
			      </div>

            <div class="bgrid">
                  <a target="_blank" rel="noopener noreferrer" href="http://livetamilcinema.com/robochef-the-worlds-first-only-fully-automated-robotic-kitchen-press-meet/">
                  <div class="price-block-gallery">
                      <img loading="lazy" src="/images/press/20.jpg" alt="Automated Robotic Kitchen" />
                      <h3 class="plan-title">RoboChef-The Worlds First & Only Fully Automated Robotic Kitchen Press Meet   </h3><br></br>by<br></br> livetamilcinema
                  </div>
                  </a>
			      </div>

            <div class="bgrid">
                  <a target="_blank" rel="noopener noreferrer" href="http://www.kuraltv.com/robochef-press-meet/">
                  <div class="price-block-gallery">
                      <img loading="lazy" src="/images/press/21.jpg" alt="Automated Robotic Kitchen" />
                      <h3 class="plan-title">முழுவதும் தானியங்கி முறையில் சமையல் செய்யும் ‘ரோபோசெஃப்’(ROBOCHEF) </h3><br></br>by<br></br> kuraltv
                  </div>
                  </a>
			      </div>

            <div class="bgrid">
                  <a target="_blank" rel="noopener noreferrer" href="http://www.3rdeyereports.com/2019/11/blog-post_2.html">
                  <div class="price-block-gallery">
                      <img loading="lazy" src="/images/press/22.jpg" alt="Automated Robotic Kitchen" />
                      <h3 class="plan-title">முழுவதும் தானியங்கி முறையில் சமையல் செய்யும் ‘ரோபோசெஃப்’ </h3><br></br>by<br></br> 3rdeyereports
                  </div>
                  </a>
			      </div>

            <div class="bgrid">
                  <a target="_blank" rel="noopener noreferrer" href="https://expressnews.asia/robo-chef-the-worlds-first-only-fully-automated-robotic-kitchen/">
                  <div class="price-block-gallery">
                      <img loading="lazy" src="/images/press/23.jpg" alt="Automated Robotic Kitchen" />
                      <h3 class="plan-title">Robo Chef – The World’s First & Only Fully Automated Robotic Kitchen </h3><br></br>by<br></br> expressnews
                  </div>
                  </a>
			      </div>

            <div class="bgrid">
                  <a target="_blank" rel="noopener noreferrer" href="http://poremurasutv.com/2019/11/02/robo-chef-the-worlds-first-only-fully-automated-robotic-kitchen/">
                  <div class="price-block-gallery">
                      <img loading="lazy" src="/images/press/24.jpg" alt="Automated Robotic Kitchen" />
                      <h3 class="plan-title">Robo Chef – The World’s First & Only Fully Automated Robotic Kitchen </h3><br></br>by<br></br> poremurasutv
                  </div>
                  </a>
			      </div>


            <div class="bgrid">
                  <a target="_blank" rel="noopener noreferrer" href="http://dtnewsonline.com/7059/">
                  <div class="price-block-gallery">
                      <img loading="lazy" src="/images/press/25.jpg" alt="Automated Robotic Kitchen" />
                      <h3 class="plan-title">Robo Chef – The World’s First & Only Fully Automated Robotic Kitchen </h3><br></br>by<br></br> dtnewsonline
                  </div>
                  </a>
			      </div>

            <div class="bgrid">
                  <a target="_blank" rel="noopener noreferrer" href="http://kalkiwebtv.com/newsdetails.php?id=18779">
                  <div class="price-block-gallery">
                      <img loading="lazy" src="/images/press/26.jpg" alt="Automated Robotic Kitchen" />
                      <h3 class="plan-title">முழுவதும் தானியங்கி முறையில் சமையல் செய்யும் 'ரோபோசெஃப்'(ROBOCHEF) </h3><br></br>by<br></br> kalkiwebtv
                  </div>
                  </a>
			      </div>

          

            <div class="bgrid">
                  <a target="_blank" rel="noopener noreferrer" href="http://newcinemaexpress.com/robochef/">
                  <div class="price-block-gallery">
                      <img loading="lazy" src="/images/press/28.jpg" alt="Automated Robotic Kitchen" />
                      <h3 class="plan-title">முழுவதும் தானியங்கி முறையில் சமையல் செய்யும் ‘ரோபோசெஃப்’(ROBOCHEF)! </h3><br></br>by<br></br> newcinemaexpress
                  </div>
                  </a>
			      </div>

            <div class="bgrid">
                  <a target="_blank" rel="noopener noreferrer" href="http://www.puthiyaosai.com/2019/11/Fully-Automated-Robotic-Kitchen-robochef.html?fbclid=IwAR2pz6hZXg9LeE5v4gK45-RlbXUvlbk4BkRDtQvUsOQTrQJVoGp3f5OzcPY">
                  <div class="price-block-gallery">
                      <img loading="lazy" src="/images/press/29.jpg" alt="Automated Robotic Kitchen" />
                      <h3 class="plan-title">முழுவதும் தானியங்கி முறையில் சமையல் செய்யும் ‘ரோபோசெஃப்’(ROBOCHEF)! </h3><br></br>by<br></br> puthiyaosai
                  </div>
                  </a>
			      </div>

            <div class="bgrid">
                  <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/MakkalMurasu/status/1190512936659369984?s=08">
                  <div class="price-block-gallery">
                      <img loading="lazy" src="/images/press/30.jpg" alt="Automated Robotic Kitchen" />
                      <h3 class="plan-title">‘ரோபோசெஃப்’. இந்தியாவில் முதல் சமைக்கும் எந்திர மனிதன். </h3><br></br>by<br></br> MakkalMurasu
                  </div>
                  </a>
			      </div>
            
            <div class="bgrid">
                  <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/MakkalMurasu/status/1190512936659369984?s=08">
                  <div class="price-block-gallery">
                      <img loading="lazy" src="/images/press/30.jpg" alt="Automated Robotic Kitchen" />
                      <h3 class="plan-title">‘ரோபோசெஃப்’. இந்தியாவில் முதல் சமைக்கும் எந்திர மனிதன். </h3><br></br>by<br></br> MakkalMurasu
                  </div>
                  </a>
			      </div>
            
            <div class="bgrid">
                  <a target="_blank" rel="noopener noreferrer" href="http://hotnewsexpress.com/2019/11/02/robochef/">
                  <div class="price-block-gallery">
                      <img loading="lazy" src="/images/press/31.jpg" alt="Automated Robotic Kitchen" />
                      <h3 class="plan-title">முழுவதும் தானியங்கி முறையில் சமையல் செய்யும் ‘ரோபோசெஃப்’(ROBOCHEF) </h3><br></br>by<br></br> hotnewsexpress
                  </div>
                  </a>
			      </div>
            
            <div class="bgrid">
                  <a target="_blank" rel="noopener noreferrer" href="https://www.aanthaireporter.com/69856-2/">
                  <div class="price-block-gallery">
                      <img loading="lazy" src="/images/press/32.jpg" alt="Automated Robotic Kitchen" />
                      <h3 class="plan-title">‘ரோபோசெஃப்’. இந்தியாவில் முதல் சமைக்கும் எந்திர மனிதன் அறிமுகம்! </h3><br></br>by<br></br> aanthaireporter
                  </div>
                  </a>
			      </div>  

            <div class="bgrid">
                  <a target="_blank" rel="noopener noreferrer" href="https://moviewingz.com/%e0%ae%87%e0%ae%a8%e0%af%8d%e0%ae%a4%e0%ae%bf%e0%ae%af%e0%ae%be%e0%ae%b5%e0%ae%bf%e0%ae%b2%e0%af%8d-%e0%ae%ae%e0%af%81%e0%ae%a4%e0%ae%b2%e0%af%8d-%e0%ae%ae%e0%af%81%e0%ae%b1%e0%af%88%e0%ae%af-2/">
                  <div class="price-block-gallery">
                      <img loading="lazy" src="/images/press/33.jpg" alt="Automated Robotic Kitchen" />
                      <h3 class="plan-title">இந்தியாவில் முதல் முறையாக தானியங்கி சமையல் எந்திர மனிதன் அறிமுகமானது ரோபோசெஃப்.!! </h3><br></br>by<br></br> moviewingz
                  </div>
                  </a>
			      </div> 

            <div class="bgrid">
                  <a target="_blank" rel="noopener noreferrer" href="http://amtv.asia/15950/">
                  <div class="price-block-gallery">
                      <img loading="lazy" src="/images/press/33.jpg" alt="Automated Robotic Kitchen" />
                      <h3 class="plan-title">Robo Chef – The World’s First & Only Fully Automated Robotic Kitchen,Today </h3><br></br>by<br></br> AM TV
                  </div>
                  </a>
			      </div> 

            <div class="bgrid">
                  <a target="_blank" rel="noopener noreferrer" href="http://arjunatv.in/11815/">
                  <div class="price-block-gallery">
                      <img loading="lazy" src="/images/press/34.jpg" alt="Automated Robotic Kitchen" />
                      <h3 class="plan-title">Robo Chef – The World’s First & Only Fully Automated Robotic Kitchen </h3><br></br>by<br></br> Arjunatv
                  </div>
                  </a>
			      </div> 

            <div class="bgrid">
                  <a target="_blank" rel="noopener noreferrer" href="https://chennaivision.com/tamil-movies/robo-chef-the-worlds-first-only-fully-automated-robotic-kitchen/">
                  <div class="price-block-gallery">
                      <img loading="lazy" src="/images/press/35.jpg" alt="Automated Robotic Kitchen" />
                      <h3 class="plan-title">Robo Chef – The World’s First & Only Fully Automated Robotic Kitchen </h3><br></br>by<br></br> Chennaivision
                  </div>
                  </a>
			      </div> 

            <div class="bgrid">
                  <a target="_blank" rel="noopener noreferrer" href="http://puthiyaparvaitv.com/archives/8611">
                  <div class="price-block-gallery">
                      <img loading="lazy" src="/images/press/36.jpg" alt="Automated Robotic Kitchen" />
                      <h3 class="plan-title">முழுவதும் தானியங்கி முறையில் சமையல் செய்யும் ‘ ரோபோசெஃப் ‘ ( ROBOCHEF ) ! </h3><br></br>by<br></br> PuthiyaParvaiTV
                  </div>
                  </a>
			      </div> 

            <div class="bgrid">
                  <a target="_blank" rel="noopener noreferrer" href="http://deccannews.info/3116/">
                  <div class="price-block-gallery">
                      <img loading="lazy" src="/images/press/37.jpg" alt="Automated Robotic Kitchen" />
                      <h3 class="plan-title">ரோபோசெஃப் என்ற இயந்திர மனிதன் </h3><br></br>by<br></br> deccannews
                  </div>
                  </a>
			      </div> 


            <div class="bgrid">
                  <a target="_blank" rel="noopener noreferrer" href="https://cinemalee.com/%e0%ae%ae%e0%af%81%e0%ae%b4%e0%af%81%e0%ae%b5%e0%ae%a4%e0%af%81%e0%ae%ae%e0%af%8d-%e0%ae%a4%e0%ae%be%e0%ae%a9%e0%ae%bf%e0%ae%af%e0%ae%99%e0%af%8d%e0%ae%95%e0%ae%bf-%e0%ae%ae%e0%af%81%e0%ae%b1%e0%af%88/">
                  <div class="price-block-gallery">
                      <img loading="lazy" src="/images/press/38.jpg" alt="Automated Robotic Kitchen" />
                      <h3 class="plan-title">ரோபோசெஃப் என்ற இயந்திர மனிதன் </h3><br></br>by<br></br> cinemalee
                  </div>
                  </a>
			      </div> 

            <div class="bgrid">
                  <a target="_blank" rel="noopener noreferrer" href="http://view7media.com/robo-chef-worlds-first-fully-automated-robotic-kitchen/">
                  <div class="price-block-gallery">
                      <img loading="lazy" src="/images/press/39.jpg" alt="Automated Robotic Kitchen" />
                      <h3 class="plan-title">Robo Chef – The World’s First & Only Fully Automated Robotic Kitchen </h3><br></br>by<br></br> view7media
                  </div>
                  </a>
			      </div> 

            <div class="bgrid">
                  <a target="_blank" rel="noopener noreferrer" href="http://flickstatus.com/tamil/robo-chef-the-worlds-first-only-fully-automated-robotic-kitchen.html">
                  <div class="price-block-gallery">
                      <img loading="lazy" src="/images/press/40.jpg" alt="Automated Robotic Kitchen" />
                      <h3 class="plan-title">Robo Chef – The World’s First & Only Fully Automated Robotic Kitchen </h3><br></br>by<br></br> flickstatus
                  </div>
                  </a>
			      </div> 

            <div class="bgrid">
                  <a target="_blank" rel="noopener noreferrer" href="http://vtv24x7.com/robo-chef-the-worlds-first-only-fully-automated-robotic-kitchen/">
                  <div class="price-block-gallery">
                      <img loading="lazy" src="/images/press/41.jpg" alt="Automated Robotic Kitchen" />
                      <h3 class="plan-title">Robo Chef – The World’s First & Only Fully Automated Robotic Kitchen </h3><br></br>by<br></br> vtv24x7
                  </div>
                  </a>
			      </div> 

            <div class="bgrid">
                  <a target="_blank" rel="noopener noreferrer" href="http://kalaipoonga.net/archives/40583">
                  <div class="price-block-gallery">
                      <img loading="lazy" src="/images/press/42.jpg" alt="Automated Robotic Kitchen" />
                      <h3 class="plan-title">முழுவதும் தானியங்கி முறையில் சமையல் செய்யும் ‘ரோபோசெஃப்’(ROBOCHEF) </h3><br></br>by<br></br> KalaiPoonga
                  </div>
                  </a>
			      </div> 

            <div class="bgrid">
                  <a target="_blank" rel="noopener noreferrer" href="https://www.chennaicitynews.net/news/robo-chef-the-worlds-first-only-fully-automated-robotic-kitchen-97826/">
                  <div class="price-block-gallery">
                      <img loading="lazy" src="/images/press/43.jpg" alt="Automated Robotic Kitchen" />
                      <h3 class="plan-title">Robo Chef – The World’s First & Only Fully Automated Robotic Kitchen </h3><br></br>by<br></br> ChennaiCityNews
                  </div>
                  </a>
			      </div> 

            <div class="bgrid">
                  <a target="_blank" rel="noopener noreferrer" href="http://www.kollywoodmix.com/archives/61401">
                  <div class="price-block-gallery">
                      <img loading="lazy" src="/images/press/44.jpg" alt="Automated Robotic Kitchen" />
                      <h3 class="plan-title">Robo Chef - The World’s First & Only Fully Automated Robotic Kitchen </h3><br></br>by<br></br> Kollywoodmix
                  </div>
                  </a>
			      </div> 

            <div class="bgrid">
                  <a target="_blank" rel="noopener noreferrer" href="http://indiastarsnow.com/2019/11/01/robo-chef-the-worlds-first-only-fully-automated-robotic-kitchen/">
                  <div class="price-block-gallery">
                      <img loading="lazy" src="/images/press/45.jpg" alt="Automated Robotic Kitchen" />
                      <h3 class="plan-title">Robo Chef – The World’s First & Only Fully Automated Robotic Kitchen </h3><br></br>by<br></br> indiastarsnow
                  </div>
                  </a>
			      </div> 

            <div class="bgrid">
                  <a target="_blank" rel="noopener noreferrer" href="https://sivaprfactory.blogspot.com/2019/11/robo-chef-worlds-first-only-fully.html">
                  <div class="price-block-gallery">
                      <img loading="lazy" src="/images/press/46.jpg" alt="Automated Robotic Kitchen" />
                      <h3 class="plan-title">Robo Chef – The World’s First & Only Fully Automated Robotic Kitchen </h3><br></br>by<br></br> Sivaprfactory
                  </div>
                  </a>
			      </div> 

            <div class="bgrid">
                  <a target="_blank" rel="noopener noreferrer" href="http://indiastarsnow.com/2019/11/01/robo-chef-the-worlds-first-only-fully-automated-robotic-kitchen/">
                  <div class="price-block-gallery">
                      <img loading="lazy" src="/images/press/47.jpg" alt="Automated Robotic Kitchen" />
                      <h3 class="plan-title">Robo Chef – The World’s First & Only Fully Automated Robotic Kitchen </h3><br></br>by<br></br> indiastarsnow
                  </div>
                  </a>
			      </div> 


            <div class="bgrid">
                  <a target="_blank" rel="noopener noreferrer" href="http://hellomadras.com/the-worlds-first-only-fully-automated-robotic-kitchen/">
                  <div class="price-block-gallery">
                      <img loading="lazy" src="/images/press/48.jpg" alt="Automated Robotic Kitchen" />
                      <h3 class="plan-title">The World’s First & Only Fully Automated Robotic Kitchen </h3><br></br>by<br></br> hellomadras
                  </div>
                  </a>
			      </div> 

         </div>



      </div> 
      </section>
    )
  }
}

export default Catering
